.signatureArea {
    width: 600px;
    max-width: 600px;
    max-height: 300px;
    background-color: white;
}

.mainSign {
    width: 600px;
}

#content {
    width: 600px;
    padding: 0;
}

.sideBar {
    width: 21px;
    height: 202px;
    float: left;
}

.infoArea {
    width: 360px;
    float: left;
    padding: 10px;
    padding-left: 30px;
}

.name {
    padding: 0px;
    margin: 0px;
    font-size: 20px;
    font-style: normal;
    line-height: 28px;
    font-weight: bold;
}

.text {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
}

.phone {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
}

.iconArea {
    padding-top: 10px;
    float: right;
}

.iconLink {
    padding: 0px 5px 0px 10px;
    margin: 0px;
    margin-bottom: 5px;
    background-color: transparent;
    color: white;
    text-decoration: none !important;
    list-style: none;
}

.iconImg {
    vertical-align: middle;
}

.brandSignature {
    width: 217px;
    height: 202px;
    float: right;
}
