.form-item {
    margin-bottom: 10px;
}

.ant-input-group-addon {
    text-align: start !important;
    background-color: #d9d9d9;
    width: 25% !important;
    border-radius: 2px 0px 0px 2px !important;
}

.ant-input {
    width: 100% !important;
    border-radius: 0px 2px 2px 0px !important;
}

.ant-select-selector {
    border-radius: 0px 2px 2px 0px !important;
}

.label {
    color: black !important;
    width: 25% !important;
    margin-top: 1px !important;
    border-radius: 2px 0px 0px 2px !important;
}

.ant-input-disabled {
    border-radius: 2px 0px 0px 2px;
}

.clearButton {
    width: 25%;
    height: 30px;

    background: #ff9526;

    border-radius: 4px;
}

.ant-radio-group {
    border: 1px solid #d9d9d9;
    white-space: nowrap !important;
    height: 32px;
}

.ant-radio-wrapper {
    max-width: 33%;
    padding-left: 5px;
    padding-top: 5px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
    border-right: 1px solid #d9d9d9;
}

.confirmButton {
    width: 75%;
    height: 30px;

    background: #7dba05;
    border-radius: 4px;
}

.center {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .ant-input-group-addon {
        display: none !important;
    }
    .label {
        display: none !important;
    }
    .form-item {
        width: 100% !important;
    }
}
:where(.css-dev-only-do-not-override-1hyej8k).ant-radio-button-wrapper:last-child {
    border-start-end-radius: 2px !important;
    border-end-end-radius: 2px !important;
}

:where(.css-dev-only-do-not-override-1hyej8k).ant-radio-button-wrapper:first-child {
    border-radius: 0px !important;
}
