.table-row-light {
    background-color: #ffffff !important;
}

.table-row-dark,
.table-row-dark .ant-table-cell-fix-left,
.table-row-dark .ant-table-cell-fix-right {
    background-color: #f7f7f7 !important;
    /* background-color: #dadada !important; */
}

.table-link {
    width: 100%;
}

.table-link span {
    margin: 5px;
    float: right;
}

.ant-spin-dot-item {
    background-color: #ff9626 !important;
}

table .ant-typography {
    color: #ff9626;
}

table .ant-typography .anticon-check {
    color: #34985c !important;
}

table .ant-typography .anticon-close {
    color: #f6394d !important;
}

/* 
.ant-table-thead tr th {    
    background-color: #ff9626 !important;
} */
