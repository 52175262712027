#signature {
    display: flex;
    justify-content: center;
}

.copy-button {
    width: 100%;
    height: 30px;

    background: #7dba05;
    border-radius: 4px;
}
