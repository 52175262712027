.content {
    /* width: 100%;
    height: 100%; */
    border-radius: 4px;
}

.content-title {
    width: 100%;
    padding: 10px;
    color: white;
}

.content-title h3 {
    font-size: 32px;
}

.content-title p {
    font-size: 16px;
}

.main {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
}
