#authArea {
    padding: 5px;
    padding-top: 10px;

    width: 400px;
    height: 500px;

    border-radius: 10px;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.textInput {
    margin-top: 10%;
    padding: 5px;

    height: 40px;

    background: #ffffff;

    border: 1px solid #e3e3e3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
}

.loginBtn {
    font-size: 1.1rem;

    padding: 5px;
    margin-top: 30px;

    width: 100%;
    height: 40px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: none;
}

.errorMsg {
    width: 70%;
    font-size: 0.9rem;
    color: #f6394d;
    text-align: center;
}
