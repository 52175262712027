* {
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: calibri, sans-serif;
    font-weight: 400;
    font-size: 1em;
}

.ant-spin {
    color: #ff9626 !important;
}
