.h-100 {
    height: 100vh;
}

.ant-menu-item-selected > span {
    color: white;
}

.sider-format > ul > li {
    background-color: #2b2b2b;
}

.drop-shadow {
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
}

.ant-layout .ant-layout-sider-trigger {
    background-color: #2b2b2b;
}

#sider .ant-menu-item-only-child {
    background-color: #494949;
}

#sider ul {
    background-color: #5c5c5c;
}

.ant-menu-item-selected {
    background-color: #ff9526 !important;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ff9526 !important;
}

.ant-menu-submenu-open > .ant-menu-submenu-title {
    background-color: #ff9526 !important;
}

.border-test {
    border: 1px solid red;
}

.last {
    position: absolute !important;
    right: 5% !important;
}

.title {
    width: 30px;
}

@media (max-width: 768px) {
    .title {
        display: none !important;
    }
}

.ant-layout {
    background-color: #e3e3e3 !important;
}

.ant-layout-content {
    border-radius: 4px;
    padding: 0 !important;
    margin: 0;
}

.ant-layout-header .ant-menu-item-selected {
    background-color: #2b2b2b !important;
}

.ant-divider-inner-text {
    color: #e3e3e3;
}
